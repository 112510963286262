import { useState, useEffect } from "react";
import {
  Typography,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import {
  ErrorOutlined,
  ArrowForward,
  Edit,
  Lock,
  LockOpen,
  SendOutlined,
  DeleteOutline,
  RemoveRedEyeOutlined,
  AssignmentIndOutlined,
  Close,
  Person,
  Phone,
  DateRangeOutlined,
  LineWeightOutlined,
  TopicRounded,
} from "@mui/icons-material";
import { basePath } from "../../util/basePath";
import styles from "../../styles/sidenav.module.css";
import axios from "axios";
import { Formik, Form, ErrorMessage, Field } from "formik";
import ErrorText from "../../components/ErrorText";
import * as Yup from "yup";

import ErrorModal from "../../components/ErrorModal";
import DisplayProject from "./DisplayProject";

const AddComment = ({ id, currentInternship, user_id }) => {
  const [lecturerList, setLecturerList] = useState(null);
  const [lecListLoading, setLecListLoading] = useState(false);
  const [lecListError, setLecListError] = useState(null);

  const [loadingInternship, setLoadingInternship] = useState(false);
  const [errorInternship, setErrorInternship] = useState(null);
  const [feedbackInternship, setFeedbackInternship] = useState(null);

  // useEffect(() => {
  //   setLecListLoading(true);
  //   setLecturerList(null);
  //   setLecListError(null);
  //   //get lecturer assigned details
  //   const getLecturerList = async () => {
  //     const empStatus = "all";
  //     const userStatus = "all";
  //     try {
  //       const list = await axios.get(
  //         `${basePath.url}users/get_users/${empStatus}/${userStatus}`
  //       );

  //       if (list.data.data.length > 0) {
  //         setLecturerList(list.data.data);
  //         setLecListError(null);
  //       } else {
  //         setLecListLoading(false);
  //         setLecListError(null);
  //         setLecturerList([]);
  //       }
  //     } catch (error) {
  //       setLecListLoading(false);
  //       setLecListError(error.message);
  //     }
  //   };

  //   getLecturerList();
  // }, []);

  const initialValues = {
    lecComments: "",
  };

  const onSubmitInternship = async (values, onSubmitProps) => {
    setLoadingInternship(true);
    setErrorInternship(null);
    setFeedbackInternship(null);

    const data = {
      marks_awarded: values.marksAwarded,
      comment: values.lecComments,
      project_id: id,
    };

    const baseUrl = `${basePath.url}project/add_comment`;
    try {
      const result = await axios.post(baseUrl, data);

      setLoadingInternship(false);
      setErrorInternship(null);

      if (result.data.message === "success") {
        //console.log(result.data.user);

        onSubmitProps.setSubmitting(false);
        if (result.data.data.length < 1) {
          setFeedbackInternship("Error Adding Comments");
        }
        setFeedbackInternship("Project Updated Successfully");
        // console.log(result.data.data);
      } else {
        setErrorInternship("Error Updating project");
        setLoadingInternship(false);
        onSubmitProps.setSubmitting(false);
        setFeedbackInternship(null);
      }
    } catch (err) {
      setLoadingInternship(false);
      setErrorInternship(err.message);
      // console.log(err);
      onSubmitProps.setSubmitting(false);
    }
  };

  const validationSchema = Yup.object({
    lecComments: Yup.string().required("Please enter comment"),
    // marksAwarded: Yup.number().required("Please enter marks, 0 if no mark "),
  });

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 3,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 3,
              paddingLeft: 10,
            }}
          >
            <LineWeightOutlined style={{ fontSize: 15 }} />
            <Typography variant={"subtitle2"} style={{ fontSize: 12 }}>
              {currentInternship.project_level} &nbsp;
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 3,
              paddingLeft: 10,
            }}
          >
            <TopicRounded style={{ fontSize: 15 }} />
            <Typography variant={"subtitle2"} style={{ fontSize: 12 }}>
              {currentInternship.project_title}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 3,
              paddingLeft: 10,
            }}
          >
            <DateRangeOutlined style={{ fontSize: 15 }} />
            <Typography variant={"subtitle2"} style={{ fontSize: 12 }}>
              {new Date(currentInternship.createdAt).toLocaleString()}
            </Typography>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {currentInternship.status === "OPEN" ? (
            <LockOpen color="success" />
          ) : (
            <Lock color="warning" />
          )}
          <Typography
            variant="subtitle1"
            style={{ fontSize: 12, paddingRight: 10 }}
          >
            {currentInternship.status.toLowerCase()}
          </Typography>
        </div>
      </div>
      {loadingInternship && (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            marginTop: "50px",
          }}
        >
          <CircularProgress />
        </div>
      )}
      <div>
        {errorInternship && (
          <div className="error_box">
            <Typography variant="subtitle2">{errorInternship}</Typography>
          </div>
        )}
      </div>
      <div>
        {feedbackInternship && (
          <div className="success_box">
            <Typography variant="subtitle2">{feedbackInternship}</Typography>
          </div>
        )}
      </div>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitInternship}
        validationSchema={validationSchema}
      >
        {(formikProps) => {
          return (
            <Form>
              <div className={styles.mainContent}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  {/* <div className="formInput">
                    <label>Marks Awarded :</label>
                    <Field
                      type="number"
                      name="marksAwarded"
                      id="marksAwarded"
                    />

                    <ErrorMessage name="marksAwarded" component={ErrorText} />
                  </div> */}
                  <div className="formInput">
                    <label>Comments:</label>
                    <Field
                      as="textarea"
                      id="lecComments"
                      name="lecComments"
                      placeholder="Add Comment"
                      style={{ fontSize: 12, minWidth: 400 }}
                    />
                    <ErrorMessage name="lecComments" component={ErrorText} />
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  marginTop: 10,
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  // color="success"
                  size="small"
                  endIcon={<SendOutlined />}
                  //style={{ width: "100%", backgroundColor: "green" }}
                  //className={styles.submitButton}
                  style={{ margin: 10, marginTop: 0 }}
                  disabled={!formikProps.isValid || formikProps.isSubmitting}
                >
                  Submit
                </Button>
                <Button
                  type="reset"
                  variant="outlined"
                  size="small"
                  //style={{ width: "100%", backgroundColor: "green" }}
                  //className={styles.submitButton}
                  style={{ margin: 10, marginTop: 0 }}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AddComment;
