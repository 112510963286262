import { UseSelector, useDispatch, useSelector } from "react-redux";
import { login, logout } from "../redux/logger/loggerActions";
import { Button } from "@mui/material";
import Axios from "axios";
import { basePath } from "../util/basePath";
import ProfileImageName from "./ProfileImageName.js";


import {
  Logout,
  LogoutOutlined,
  LogoutRounded,
  LogoutTwoTone,
  Menu,
  ExpandMoreOutlined,
  ExpandLessOutlined
} from "@mui/icons-material";
import { useState } from "react";
import DropDownProfileBox from "./DropDownProfileBox.js";
const NavBar = ({showMenuBtn,handleShowMenuButton}) => {

  const [showSubMenu,setShowSubMenu]=useState(false);
  const dispatch = useDispatch();

  const logger=useSelector((state)=>{
    return state.logger;
  })

  let userImage = logger.user
    ? `${basePath.userImageUrl}${logger.user.avatar}`
    : null;

  const handleLogout = async () => {
    try {
      const signout = await Axios.get(`${basePath.url}logout`);
      dispatch(logout());
    } catch (error) {
      console.log(error);
    }
  };

  
  

  return (
    <>
    <div style={{position:"relative",padding:0,margin:0,width:'100%'}}>
    <ul
      className="nav"
     
    >
      
      <li className="toggleMenu" onClick={handleShowMenuButton}>
       <Menu  style={{ fontWeight: 600,fontSize:35,color:"white",boxSizing:"border-box" }}/>
       
     
      </li>
      <li>
        <Button
          style={{ color: "white", fontWeight: 600 }}
          size="medium"
          onClick={handleLogout}
          endIcon={<LogoutRounded  style={{ fontWeight: 600 }} />}
        >
          Logout
        </Button>
      </li>

      
        <li className="navItem" style={{ float: "right",cursor:"pointer" }} onClick={()=>{
          return setShowSubMenu((val)=>{
            return !val;
          })
        }}>
        {/* <Button
          style={{ color: "white", fontWeight: 600 }}
          size="medium"
          onClick={handleLogout}
          endIcon={<LogoutRounded  style={{ fontWeight: 600 }} />}
        >
          Logout
        </Button> */}
        <ProfileImageName/>
        {showSubMenu ? <ExpandLessOutlined style={{cursor:"pointer",marginLeft:5,marginRight:5}}/> : <ExpandMoreOutlined style={{cursor:"pointer",marginLeft:5,marginRight:5}}/>}
       
        </li>
        
    </ul>
    <div className="sub-menu-wrap" style={{maxHeight:showSubMenu ? 400 : 0}}>
          <div className="sub-menu">
          <DropDownProfileBox/>
          </div>
        </div>
       
    </div>
    </>
   
  );
};

export default NavBar;
