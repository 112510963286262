import React, { Component } from "react";
import { UseSelector, useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { basePath } from "../util/basePath";

const ProfileImageName = () => {
  const logger = useSelector((state) => {
    return state.logger;
  });

  let userImage = logger.user
    ? `${basePath.userImageUrl}${logger.user.avatar}`
    : null;

  return (
    <>
      <div className="profile-wrapper">
        <div className="profile-image">
          {logger.user.avatar !== "" ? (
            <img className="user-img" src={userImage} alt="Profile Image" />
          ) : (
            <AccountCircle style={{ fontSize: 30 }} color="action" />
          )}
        </div>
        <div className="profile-name">
          <div
            style={{
              fontSize: 10,
              marginLeft: 10,
              fontWeight: 600,
              color: "white",
            }}
          >
            {logger.user.otherNames}
          </div>
          <div
            style={{
              fontSize: 10,
              marginLeft: 10,
              fontWeight: 600,
              color: "white",
            }}
          >
            {logger.user.surname}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileImageName;
