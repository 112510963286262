import { useEffect, useState } from "react";
import { UseSelector, useSelector } from "react-redux/es/hooks/useSelector";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import {
  ErrorOutlined,
  ArrowForward,
  Edit,
  Lock,
  LockOpen,
  SendOutlined,
  DeleteOutline,
  RemoveRedEyeOutlined,
  AssignmentIndOutlined,
  Close,
  Comment,
} from "@mui/icons-material";
import { basePath } from "../../util/basePath";
import styles from "../../styles/sidenav.module.css";
import axios from "axios";

import ErrorModal from "../../components/ErrorModal";
import DisplayProject from "./DisplayProject";
import AddComment from "./AddComment";

const ProjectList = () => {
  const [projectList, setProjectList] = useState(null);
  const [employeeDetails, setEmployeeDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentUserId, setCurrentUserId] = useState("");
  const [startDate, setStartDate] = useState("");

  const [internshipList, setInternshipList] = useState(null);
  // const [employeeDetails, setEmployeeDetails] = useState(null);
  const [lecturerList, setLecturerList] = useState(null);
  const [lecListLoading, setLecListLoading] = useState(false);
  const [studentList, setStudentList] = useState(null);
  const [studentsAdmissionsList, setStudentsAdmissionsList] = useState([]);
  const [downloadList, setDownloadList] = useState([]);
  const [studentListLoading, setStudentListLoading] = useState(false);

  const [feedBack, setFeedback] = useState(null);
  const [loadingInternship, setLoadingInternship] = useState(false);
  const [feedbackInternship, setFeedbackInternship] = useState(null);
  const [errorInternship, setErrorInternship] = useState(null);
  const [openViewInternship, setOpenViewInternship] = useState(false);

  const [selectedId, setSelectedId] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [currentInternship, setCurrentInternship] = useState(null);
  const navigate = useNavigate();
  const url = basePath.url;

  const [modalLoading, setModalLoading] = useState(false);
  const [modalLoadingMsg, setModalLoadingMsg] = useState(null);
  const [initialModalMsg, setInitialModalMsg] = useState(null);
  const [feedBackMsg, setFeedBackMsg] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);

  const [openLecModal, setOpenLecModal] = useState(false);
  const [openCommentModal, setOpenCommentModal] = useState(false);

  const user = useSelector((state) => {
    return state.logger.user;
  });

  const handleToggle = () => {
    setDeleteOpen((val) => {
      return !val;
    });
  };

  const handleResetModal = () => {
    setInitialModalMsg(null);
    setDeleteOpen(false);
    setModalLoading(false);
    setModalLoadingMsg(null);
    setFeedBackMsg(null);
  };
  useEffect(() => {
    setLoading(true);
    setError(null);

    const getList = async () => {
      const list = await axios.post(`${basePath.url}project/student`, {
        data: user.admissionNo,
      });
      try {
        setInternshipList(list.data.data);
        console.log(list.data.data);
        console.log(`list.data.data`);
        if (list.data.data.length > 0) {
          // console.log(res.data.data);
          //const user_id = list.data.data[0].lecturer_assigned_id;

          // setCurrentUserId(user_id);
          // if (list.data.data[0].lecturer_assigned_id) {
          //   const employee = await axios.get(
          //     `${basePath.url}users/get-by-user-id/${list.data.data[0].lecturer_assigned_id}`
          //   );

          //   if (employee.data.data.length > 0) {
          //     console.log(employee.data.data[0])
          //     setEmployeeDetails(employee.data.data[0]);
          //   }
          // }

          setLoading(false);
          setError(null);
        } else {
          setLoading(false);
          setError(null);
        }
      } catch (error) {
        setLoading(false);
        setError(error);
      }
    };
    getList();
  }, []);

  // useEffect(() => {
  //   //get lecturer assigned details
  //   const getEmployee = async () => {
  //     if (currentUserId) {
  //       const employee = await axios.get(
  //         `${basePath.url}users/get-by-user-id/${currentUserId}`
  //       );
  //       console.log(
  //         `employee details:${employee.data.data} user_id:${currentUserId}`
  //       );
  //       if (employee.data.data.length > 0) {
  //         setEmployeeDetails(employee.data.data);
  //       }
  //     }
  //   };

  //   getEmployee();
  // }, [projectList]);

  return (
    <>
      {/* <ErrorModal /> */}
      {/* <ErrorModal
        open={deleteOpen}
        handleToggle={handleToggle}
        message={initialModalMsg && initialModalMsg}
        callBack={currentAction === "open" ? handleOpen : handleClose}
        loading={modalLoading}
        loadingMsg={modalLoadingMsg}
        reset={handleResetModal}
        feedBackMsg={feedBackMsg}
      /> */}

      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={openViewInternship}
        onClose={() => {
          setOpenViewInternship((state) => {
            return !state;
          });
        }}

        // PaperProps={{ sx: { height: "100%" } }}
      >
        <DialogTitle
          borderBottom={1}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle2">Project / Proposal</Typography>
          <Close
            style={{ cursor: "pointer" }}
            onClick={() => {
              setOpenViewInternship((state) => {
                return !state;
              });
            }}
          />{" "}
        </DialogTitle>
        <DialogContent style={{ margin: "10px", backgroundColor: "white" }}>
          <DisplayProject
            id={selectedId}
            setOpenViewInternship={setOpenViewInternship}
          />
        </DialogContent>
      </Dialog>

      {/* add comment dialog */}
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={openCommentModal}
        onClose={() => {
          setOpenCommentModal((state) => {
            return !state;
          });
        }}

        // PaperProps={{ sx: { height: "100%" } }}
      >
        <DialogTitle
          borderBottom={1}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle2">
            Add project/proposal Comment
          </Typography>
          <Close
            style={{ cursor: "pointer" }}
            onClick={() => {
              setOpenCommentModal((state) => {
                return !state;
              });
            }}
          />{" "}
        </DialogTitle>
        <DialogContent style={{ margin: "10px", backgroundColor: "white" }}>
          <AddComment
            id={selectedId}
            currentInternship={currentInternship}
            user_id={user.user_id}
          />
        </DialogContent>
      </Dialog>

      <div className={styles.topContent}>
        <Typography
          variant="h6"
          style={{ fontWeight: 400, color: "#282727", padding: 10 }}
        >
          Project / Proposal
        </Typography>
      </div>
      {loading === true ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            marginTop: "50px",
          }}
        >
          <CircularProgress />
        </div>
      ) : !internshipList || internshipList.length < 1 ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            marginTop: "50px",
          }}
        >
          <ErrorOutlined style={{ fontSize: 50 }} color="warning" />
          <Typography variant="h6" style={{ textAlign: "center" }}>
            You have not added Project / Proposal
          </Typography>
          <Button
            variant="outlined"
            color="success"
            size={"medium"}
            endIcon={<ArrowForward />}
            onClick={() => {
              navigate("/project-add");
            }}
          >
            Add New Project / Proposal
          </Button>
        </div>
      ) : (
        <>
          <div className={styles.mainContent}>
            <p style={{ margin: "10px", color: "crimson" }}>{error && error}</p>
            <table id={styles.general_table}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>
                    <Typography
                      variant="subtitle2"
                      style={{ fontWeight: 600, fontSize: 12 }}
                    >
                      Level
                    </Typography>
                  </th>
                  <th>
                    <Typography
                      variant="subtitle2"
                      style={{ fontWeight: 600, fontSize: 12 }}
                    >
                      Title
                    </Typography>
                  </th>
                  {/* <th>Organization</th> */}
                  {/* <th>Location</th> */}
                  <th>
                    <Typography
                      variant="subtitle2"
                      style={{ fontWeight: 600, fontSize: 12 }}
                    >
                      Date Created
                    </Typography>
                  </th>

                  <th>
                    <Typography
                      variant="subtitle2"
                      style={{ fontWeight: 600, fontSize: 12 }}
                    >
                      Payment
                    </Typography>
                  </th>
                  {/* <th>
                  Assessment <br /> Status
                </th> */}
                  <th>
                    {" "}
                    <Typography
                      variant="subtitle2"
                      style={{ fontWeight: 600, fontSize: 12 }}
                    >
                      Status
                    </Typography>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {internshipList &&
                  internshipList.map((internship, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {" "}
                          <Typography
                            variant="subtitle2"
                            style={{ fontWeight: 400, fontSize: 12 }}
                          >
                            {index + 1}
                          </Typography>
                        </td>
                        <td>
                          {" "}
                          <Typography
                            variant="subtitle2"
                            style={{ fontWeight: 400, fontSize: 12 }}
                          >
                            {internship.project_level}
                          </Typography>
                        </td>
                        <td>
                          {" "}
                          <Typography
                            variant="subtitle2"
                            style={{ fontWeight: 400, fontSize: 12 }}
                          >
                            {internship.project_title}
                          </Typography>
                        </td>
                        {/* <td> <Typography variant="subtitle2" style={{fontWeight:400,fontSize:12}}></Typography>{internship.organization.toUpperCase()}</td>
                      <td> <Typography variant="subtitle2" style={{fontWeight:400,fontSize:12}}></Typography>
                        <span style={{ color: "rgb(5, 117, 33)" }}>
                          Country:{" "}
                        </span>
                        {internship.country.toUpperCase()} &nbsp;&nbsp;
                        <span style={{ color: "rgb(5, 117, 33)" }}>
                          County:{" "}
                        </span>{" "}
                        {internship.county.toUpperCase()} &nbsp;&nbsp;
                        <span style={{ color: "rgb(5, 117, 33)" }}>
                          Town:{" "}
                        </span>{" "}
                        {internship.town.toUpperCase()}
                        <br />
                        <span style={{ color: "rgb(5, 117, 33)" }}>
                          Physical Address:{" "}
                        </span>
                        {internship.physical_address}
                        </Typography>
                      </td> */}
                        <td>
                          {" "}
                          <Typography
                            variant="subtitle2"
                            style={{ fontWeight: 400, fontSize: 12 }}
                          >
                            {new Date(internship.createdAt).toDateString()}
                          </Typography>
                        </td>

                        <td>
                          {" "}
                          <Typography
                            variant="subtitle2"
                            style={{ fontWeight: 400, fontSize: 12 }}
                          >
                            {internship.project_fees_status.toUpperCase()}
                          </Typography>
                        </td>
                        {/* <td> <Typography variant="subtitle2" style={{fontWeight:400,fontSize:12}}></Typography>{internship.assessment_status.toUpperCase()}</Typography></td> */}
                        <td>
                          {" "}
                          <Typography
                            variant="subtitle2"
                            style={{ fontWeight: 400, fontSize: 12 }}
                          >
                            {internship.status}
                          </Typography>
                        </td>

                        <td>
                          {" "}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-evenly",
                              alignItems: "center",
                              flexWrap: "nowrap",

                              gap: "2px",
                            }}
                          >
                            <div>
                              {" "}
                              <Button
                                variant="outlined"
                                size="small"
                                style={{ fontSize: 11 }}
                                startIcon={
                                  <RemoveRedEyeOutlined color="info" />
                                }
                                onClick={() => {
                                  setSelectedId(internship.id);
                                  setOpenViewInternship((state) => {
                                    return !state;
                                  });
                                }}
                              >
                                View
                              </Button>
                            </div>

                            <div>
                              <Button
                                style={{ fontSize: 11 }}
                                variant="outlined"
                                size="small"
                                startIcon={<Comment color="info" />}
                                onClick={() => {
                                  setSelectedId(internship.id);
                                  setCurrentInternship(internship);
                                  setOpenCommentModal(true);
                                }}
                              >
                                Comment / Progress
                              </Button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {/* <p>loan id:{loanId}</p> */}
          </div>
        </>
      )}
    </>
  );
};

export default ProjectList;
